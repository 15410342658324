import React from "react";
import ProffLogo from "../images/proff.png";

import Stamp from "../images/stamp.png";
import Sign from "../images/sign.png";

const PaydServiceOrder = () => {
  return (
    <div>
      <div className="text-center flex justify-center">
        <img src={ProffLogo} width={500} height={102} alt="logo" />
      </div>
      <div className="py-12 text-center">
        <strong>ПРИКАЗ № 01-01/21-2</strong>
      </div>
      <div className="flex justify-between">
        <p>01 января 2021 г. </p>
        <p>г. Пыть-Ях</p>
      </div>

      <div className="text-center font-bold my-8">
        «Об установлении стоимости обучения в Частном образовательном учреждении
        дополнительного профессионального образования «Профф»
      </div>
      <p>
        В соответствии с Гражданским кодексом РФ, Федеральным законом РФ «Об
        образовании в Российской Федерации» от 29.12.2012 г. N 273-ФЗ,
        Постановлением Правительства РФ от 15 сентября 2020 г. № 1441 «Об
        утверждении Правил оказания платных образовательных услуг», в целях
        организации образовательного процесса
      </p>
      <div className="text-center my-8 font-bold">ПРИКАЗЫВАЮ:</div>
      <p>
        - Утвердить стоимость платных образовательных услуг в Частном
        образовательном учреждении дополнительного профессионального образования
        «Профф» по основным программам профессионального обучения на 2021 год
        согласно приложению № 1.
      </p>
      <p>
        - Утвердить стоимость платных образовательных услуг в Частном
        образовательном учреждении дополнительного профессионального образования
        «Профф» по дополнительным профессиональным программам на 2021 год
        согласно приложению № 2.
      </p>
      <div className="my-4">Генеральному директору Протопоповой Е.С.:</div>
      <blockquote>
        <p>
          Разместить на официальном сайте Частного образовательного учреждения
          дополнительного профессионального образования «Профф» информацию о
          стоимости платных образовательных услуг.
        </p>
      </blockquote>

      <p>Контроль над исполнением приказа оставляю за собой.</p>
      <div className="flex justify-end">
        <div className="relative ml-auto my-24">
          <img
            src={Stamp}
            alt=""
            className="absolute"
            style={{ left: 100, top: -50, width: 280 }}
          />
          <img
            src={Sign}
            alt=""
            className="w-20 h-20 absolute "
            style={{ left: 330, top: -40 }}
          />
          Генеральный директор ЧОУ &#34;Профф&#34; _________________ Е.С.
          Протопопова
        </div>
      </div>
    </div>
  );
};

export default PaydServiceOrder;
