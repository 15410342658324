import React, { useState } from 'react';

import Layout from '../components/layout';
import PaydServiceOrder from '../components/paydServiceOrder';
import PaydServicesOrderAddition from '../components/paydServicesOrderAddition';
import SEO from '../components/seo';
import Modal from '../components/modal';
import Obrazec from '../../static/obrazec_payd_services_contract.pdf';
import Obrazec3 from '../../static/three_sides_contract.pdf';
import Polozhenie from '../../static/polozhenie_o_platnyh.pdf';
import prikaz_2023 from '../../static/prikaz_o_stoimosti_2023.pdf';
import prikaz_2023_1 from '../../static/prikaz_o_stoimosti_2023_p1.pdf';
import prikaz_2023_2 from '../../static/prikaz_o_stoimosti_2023_p2.pdf';

// import PayTable from '../components/payTable';

function PaidServices() {
    const [modalOpened, setModalOpened] = useState(false);
    const [zModalOpened, setZmodalOpened] = useState(false);

    const handleModalClose = () => {
        setModalOpened(false);
        setZmodalOpened(false);
    };

    return (
        <Layout>
            <SEO
                keywords={[
                    `Платные услуги ЧОУ Профф`,
                    `Профф`,
                    `Пыть-Ях`,
                    `Профессиональное образование`,
                ]}
                title="Платные услуги"
            />

            <section className="flex flex-col items-center ">
                <h1 className=" text-center text-green-800 mb-4 text-4xl uppercase tracking-wider">
                    Платные образовательные услуги
                </h1>

                <div
                    className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
                    // onClick={() => setModalOpened(true)}
                >
                    <a
                        href={prikaz_2023}
                        target="blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-2xl text-center">
                            ПРИКАЗ № 1/20 от 09 января 2023 г.
                        </h2>
                    </a>
                </div>
                <div
                    className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
                    // onClick={() => setModalOpened(true)}
                >
                    <a
                        href={prikaz_2023_1}
                        target="blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-xl text-center">
                            ПРИЛОЖЕНИЕ №1 К ПРИКАЗУ № 1/20 от 09 января 2023 г.
                        </h2>
                    </a>
                </div>
                <div
                    className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
                    // onClick={() => setModalOpened(true)}
                >
                    <a
                        href={prikaz_2023_2}
                        target="blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-xl text-center">
                            ПРИЛОЖЕНИЕ №2 К ПРИКАЗУ № 1/20 от 09 января 2023 г.
                        </h2>
                    </a>
                </div>
                <div
                    className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
                    // onClick={() => setModalOpened(true)}
                >
                    <a
                        href={prikaz_2023}
                        target="blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-2xl text-center">
                            ПРИКАЗ № 1/20 от 09 января 2023 г.
                        </h2>
                    </a>
                </div>

                {/* <div
                    className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
                    onClick={() => setZmodalOpened(true)}
                >
                    <h2 className="text-green-700 text-2xl text-center">
                        Приложение №1 к Приказу № 01-01/21-2 от 01 января 2021
                        г.
                    </h2>
                </div> */}
                <div className="w-full bg-green-200 p-2 mt-6 rounded shadow-lg cursor-pointer">
                    <a
                        href={Polozhenie}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-2xl text-center">
                            Положение о платных образовательных услугах.pdf
                        </h2>
                    </a>
                </div>
                <div className="w-full bg-green-200 p-2 mt-6 rounded shadow-lg cursor-pointer">
                    <a href={Obrazec} target="_blank" rel="noopener noreferrer">
                        <h2 className="text-green-700 text-2xl text-center">
                            Образец договора об оказании платных образовательных
                            услуг.pdf
                        </h2>
                    </a>
                </div>
                <div className="w-full bg-green-200 p-2 mt-6 rounded shadow-lg cursor-pointer">
                    <a
                        href={Obrazec3}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-2xl text-center">
                            Образец договора об оказании платных образовательных
                            услуг (трехстронний).pdf
                        </h2>
                    </a>
                </div>

                {/* <Img fluid={data.structure2.childImageSharp.fluid} alt='Структура ' /> */}
            </section>
            {modalOpened && (
                <Modal onClose={handleModalClose}>
                    <PaydServiceOrder />
                </Modal>
            )}
            {zModalOpened && (
                <Modal onClose={handleModalClose}>
                    <PaydServicesOrderAddition />
                </Modal>
            )}
        </Layout>
    );
}

export default PaidServices;
