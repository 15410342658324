import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, onClose }) => {
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div
            className='absolute inset-0 bg-gray-900 opacity-75'
            onClick={onClose}
          ></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        >
          &#8203;
        </span>
        {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
        <div
          className=' inline-block align-bottom bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl h-screen '
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          <div
            onClick={onClose}
            className='sticky top-0 w-4 h-4 flex justify-center items-center mt-4 p-4 rounded-full hover:bg-gray-300 hover:text-red-600  cursor-pointer text-red-800'
          >
            X
          </div>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default Modal;
